.history{
    background-color: #9c424d;
    color: white;
    
    
    .heading{
        
        display: flex;
        align-items: center;
        margin-left: 10%;
        margin-right: 10%;
        // margin-top: 5%;

        p{
            text-align: justify;
            font-family: 'Prompt', sans-serif;
            font-weight: 500;
            font-size: 32px;
        }

        

    }

    .textheading{
        display: flex;
        align-items: center;
        margin-left: 10%;
        margin-top: 5%;

        .historytext{
            p{
                text-align: justify;
                font-family: 'Prompt', sans-serif;
                font-weight: 500;
                font-size: 28px;
                cursor: pointer;
            }

        }
        .todaytext{
            margin-left: 5%;
            p{
                text-align: justify;
                font-family: 'Prompt', sans-serif;
                font-weight: 300;
                font-size: 28px;
                cursor: pointer;
            }

        }

    }

    .historyContent{

        align-items: center;
        justify-content: center;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 3%;

        p{
            margin-bottom: 2%;
            text-align: justify;
            font-family: 'Prompt', sans-serif;
            font-weight: 300;
            font-size: 22px;
        }
        ol{
            li{
                margin-bottom: 0.5%;
                text-align: justify;
                font-family: 'Prompt', sans-serif;
                font-weight: 300;
                font-size: 22px;
            }
        }

        .imageposition{

            display: flex;
            justify-content: center;

            .textbox{
                justify-content: left;
                margin-right: 3%;
            }
            
            .imageContainer1{
                display: none;
                
                width: 100%;
                height: 240px;
                border: 5px solid white;
    
                img{
                    height: 100%;
                    width: 100%;
                    display: block;
                    image-rendering: pixelated;
                }
    
            }

        }

        .imageposition2{

            display: none;
            justify-content: center;
            .image2{
                width: 100%;
                height: 100%;
                justify-content: left;                
                border: 5px solid white;

                img{
                    height: auto;
                    width: 100%;
                    display: block;
                }
            }

            .textbox{

                margin-left: 3%;
            }

        }
        

        margin-bottom: 10%;

        @media (max-width: 768px) {
            p{
                font-size: 18px;
            }
            ol{
                li{
                    font-size: 18px;
                }
            }

            .imageposition{

                .imageContainer1{
                    display: none;
                    img{
                        display: none;
                    }
                }                
            }

            .imageposition2{
                .image2{
                    display: none;
                    
                }
                .textbox{
                    margin-left: 0;
                }
            }
        }
    }
}