$primary-color: #9c424d;
$secondary-color: #9c88ff;
$tertiary-color: #f5f6fa;
$dark-color: #340808;
$light-color: #7e353e;

.admin{
    margin-top: 90px;
    // display: flex;
    overflow: hidden;
}

.headerDiv{
    display: flex;
    justify-content: space-between;
}

.leftHeader{
    span {
        margin-left: 50px;
        color: white;
        text-transform: uppercase;
        font-family: "Prompt", sans-serif;
        font-size: 28px;
        font-weight: 500;
    }
}


.divButton {
    
    // left: 50%;
    text-align: center;
    display: flex;
    button {
      padding: 0.8em 1.8em;
    //   margin: 5px;
      margin-right: 2%;
      border: 2px solid black;
      position: relative;
      overflow: hidden;
      background-color: transparent;
      text-align: center;
      text-transform: uppercase;
      background: white;
      font-size: 13px;
      transition: .3s;
      z-index: 1;
      font-family: inherit;
      color: black;
      cursor: pointer;
    }
  
    button::before {
      content: '';
      width: 0;
      height: 400%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: black;
      transition: .1s ease;
      display: block;
      z-index: -1;
    }
  
    button:hover::before {
      width: 105%;
    }
  
    button:hover {
      color: White;
    }
  
    .deleteButton::before {
      background: red;
    }
  
    .disabledButton {
      background: rgb(177, 177, 177);
      border: 0px;
    }
  
    .disabledButton:hover {
      background: rgb(177, 177, 177);
      border: 0px;
      // animation: none;
    }
  
  }

.bodyDiv{
    margin-left: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;

    
    
}
.welcome {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                h1 {
                    font-size: 32px;
                    font-weight: normal;
                    // font-family: "Prompt", sans-serif;
                }

                .metrics {
                    display: flex;

                    .card {
                        width: 200px;
                        height: 100px;
                        background-color: $tertiary-color;
                        border-radius: 10px;
                        padding: 25px;
                        margin-left: 10px;
                        // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

                        h3 {
                            font-size: 16px;
                            font-weight: normal;
                            margin: 0;
                            // font-family: "Prompt", sans-serif;
                        }

                        p {
                            font-size: 24px;
                            font-weight: bold;
                            margin: 0;
                        }

                        span {
                            font-size: 16px;
                            color: $primary-color;
                        }
                    }
                }
}

.revenue {
    margin-left: 10px;
    width: 70%;
    background-color: $tertiary-color;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    table {
        width: 100%; 
        border-collapse: collapse;

        th, td {
            padding: 8px 12px;
            border-bottom: 1px solid #ddd;
            text-align: left;
        }

        th {
            background-color: white;
            color: black;
            font-weight: bold;
            position: sticky;
            top: 0;
        }

        tbody {
            display: block;
            max-height: 300px; 
            overflow-y: auto; 
            width: 100%;
            cursor: pointer;
        }

        thead, tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed; 
        }
    }
}

.headingRevenue{
    display: flex;
    justify-content: space-between;
}



@media (max-width: 708px) {

    .leftHeader{
        display: none;
    }
    .rightHeader{
        .divButton{
            margin-left: 53px;
            width: 500px;
        }
    }
    
    .welcome{
        .metrics{
            flex-direction: column;

            .card {
                margin-top: 10px;
            }

        }
    }
    .headingRevenue{
        display: flex;
        flex-direction: column;

        h2{
            font-size: 16px;
        }

        .divButton{
            margin-top: 20px;
        }

    }

    .revenue{

        width: 200px;
        padding: 25px;
        // margin-top: 10px;
        // margin-left: 10px;
        // margin-top: 10px;
        table{
            display: none;
        }
        button{
            margin: auto;
        }
    }

}