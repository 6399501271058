.footer{
    background-color: #FFD0A5;
    display: flex;
    .left{
        // margin-top: 0%;
        margin-left: 5%;
        flex: 0.5;
        justify-content: left;

        img{
            height: 40px;
            width: 160px;
        }
        @media (max-width: 768px) {
            margin-top: 2%;
            img{
                height: 30px;
                width: 120px;
            }
        }

    }

    .right{
        flex: 1;
        margin-top: 0.7%;
        margin-right: 5%;

        p{
            text-align: right;
            font-family: 'Prompt', sans-serif;
        }
        @media (max-width: 768px) {
            flex: 0.9;
            margin-top: 2.7%;
            p{
                font-size: 15px;
            }
        }
    }
   

}