.profile {
  margin-top: 120px;

  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: center;

}

.containerList {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  // text-align: start;

}

.containerDeck {
  // border: 2px solid black;
  background-color: aliceblue;
  filter: drop-shadow(5px 5px 8px rgb(57, 57, 57));
  padding: 20px;
  margin: auto;
  width: 370px;
  // text-align: center;
  border-radius: 14px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s ease;

}

.containerDeck:hover {
  transform: scale(1.08);
  transition: 0.2s ease;
  // border: 5px solid rgb(85, 85, 85);

}



.deckImage {
  height: 50px;
  width: 50px;
  // text-align: center;
  padding: 15px;
  margin-bottom: 10px;

  img{
    height: 80px;
    width: 80px;
    border-radius: 50px;
    // border: 2px solid black;
  }
}

.deckImageFull {
  height: 100px;
  width: 100px;
  // text-align: center;
  padding: 15px;
  align-items: center;
  margin: auto;
  // margin-bottom: 50px;

  img{
    height: 130px;
    width: 130px;
    border-radius: 150px;
    // border: 2px solid black;
  }
}

.imageButtons{
  margin: auto;
  align-items: center;
}

.deckImageFull:hover{
  cursor: pointer;
}

.deckUserName {
  padding: 15px;
}

.showMoreButton {
  text-align: center;
}

.buttonDiv {

  margin-top: 5%;
  left: 50%;
  text-align: center;

  button {
    padding: 0.8em 1.8em;
    // margin: 5px;
    margin-right: 2%;
    border: 2px solid black;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    background: white;
    font-size: 13px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    color: black;
    cursor: pointer;
  }

  button::before {
    content: '';
    width: 0;
    height: 400%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: black;
    transition: .1s ease;
    display: block;
    z-index: -1;
  }

  button:hover::before {
    width: 105%;
  }

  button:hover {
    color: White;
  }

  .deleteButton::before {
    background: red;
  }

  .disabledButton {
    background: rgb(177, 177, 177);
    border: 0px;
  }

  .disabledButton:hover {
    background: rgb(177, 177, 177);
    border: 0px;
    // animation: none;
  }

}

//PersonalInfo - start

.profileInformation {}

.personal,
.address,
.additional {

  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // transition: 1 ease;
  cursor: pointer;

  form {

    display: flex;
    margin: auto;
    // justify-content: s;

    input {
      width: 250px;
      padding: 12px;
      margin-bottom: 16px;
      border: 0px;
      border-bottom: 1px solid black;
      background-color: #d4d4d4;
      outline: none;
      margin-bottom: 20px;
      // box-sizing: border-box;
    }


    .right-side-form {
      margin-left: 50px;
    }
  }


}

.InfoTitle {
  display: flex;
  justify-content: space-between;
}

.createUserDiv {
  // margin-left: auto; margin-right: auto;
  width: 100px;
  height: 100px;
  margin: auto;

  button {
    margin-top: 200px;
    padding: 0.8em 1.8em;
    // margin: 5px;
    // margin-right: 2%;
    border: 2px solid black;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    background: white;
    font-size: 13px;
    transition: .3s;
    z-index: 1;
    // width: 150px;
    font-family: inherit;
    color: black;
    cursor: pointer;
  }

  button::before {
    content: '';
    width: 0;
    height: 400%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: black;
    transition: .1s ease;
    display: block;
    z-index: -1;
  }

  button:hover::before {
    width: 105%;
  }

  button:hover {
    color: White;
  }

}





@media (max-width: 708px) {

  .containerDeck {
    transform: scale(0.8);
    margin: 5px;
    padding: 2px;
  }

  .containerDeck:hover {
    transform: scale(1);
    transition: 0.2s ease;
    // border: 5px solid rgb(85, 85, 85);

  }

  .deckImageFull {
    height: 80px;
    width: 80px;
    // text-align: center;
    padding: 8px;
    align-items: center;
    margin: auto;
  }

  .personal,
  .address,
  .additional {
    form {
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
      }

      input {
        width: 250px;
        padding: 8px;
        margin-bottom: 8px;
        border: 0px;
        border-bottom: 1px solid black;
        background-color: #d4d4d4;
        outline: none;
        margin-bottom: 10px;
        // box-sizing: border-box;
      }


      .right-side-form {
        margin-left: 0px;
      }
    }
  }

  .createUserDiv{
    button{
    margin-top: 50px;
    margin-bottom: 50px;
    }
  }

}

