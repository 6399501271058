.search {
  margin-top: 75px;
  display: flex;
  justify-content: center;

}

.searchDiv {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #9c424d;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  form {
      width: 660px;
      display: flex;
      justify-content: space-between;

      // align-items: center;
      .searchbox {


          input {
              width: 500px;
              height: 40px;
              border-radius: 14px;
              filter: drop-shadow(5px 5px 8px rgb(57, 57, 57));


          }

      }

      .searchButton {
          button {
              // padding: 0.8em 1.8em;
              filter: drop-shadow(5px 5px 8px rgb(57, 57, 57));
              // margin: 5px;
              width: 150px;
              height: 40px;
              // margin-right: 2%;
              border-radius: 14px;
              border: 2px solid black;
              position: relative;
              overflow: hidden;
              background-color: transparent;
              text-align: center;
              text-transform: uppercase;
              background: white;
              font-size: 15px;
              transition: .3s;
              z-index: 1;
              font-family: inherit;
              color: black;
              cursor: pointer;
          }

          button::before {
              content: '';
              width: 0;
              height: 400%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              background: black;
              transition: .1s ease;
              display: block;
              z-index: -1;
          }

          button:hover::before {
              width: 105%;
          }

          button:hover {
              color: White;
          }
      }
  }
}

.searchResults {

  width: 800px;
  // background-color: white;
  margin-top: 100px;
  padding-top: 50px;

  .userDetails {

      background-color: beige;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      display: flex;
      margin: 20px;
      padding: 30px;
      cursor: pointer;
      

      .userName{

          p{
              font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
              font-weight: 500;
          }

      }

  }
}



@media (max-width: 708px) {
 .searchDiv {   
      form { 
          display: flex;
          justify-content: center;
          .searchbox {   
              input {
                  width: 250px;
                  height: 40px;
                  border-radius: 5px;
              }   
          }   
          .searchButton {
              button {
                  margin-left: 10px;                   
                  width: 50px;
                  height: 40px;                  
                  border-radius: 5px;
                  
              }
          }
      }
  }



}