.gallery{

    background-color: #9c424d;
    color: white;

    .heading{
        padding-top: 5%;
        margin-left: 10%;
        position: fixed;
        width: 100%;
        background-color: #9c424d;
        display: block;
        p{
            text-align: justify;
            font-family: 'Prompt', sans-serif;
            font-weight: 500;
            font-size: 32px;
            margin-bottom: 2%;

            @media (max-width: 590px) {
                font-size: 20px;
            }

            @media (max-width: 370px) {
                font-size: 4vw;
            }
        }
    }

    .sections{
        margin-top: 10%;
        display: flex;
        flex-direction: column;

        .left{
            margin-left: 10%;
            margin-right: 5%;
            margin-top: 2%;
            width: 20%;
            float: left;
            position: fixed;
            display: flex;
            flex-direction: column;
            p{
                margin-bottom: 5%;
                font-weight: 300;
                font-size: 25px;
                cursor: pointer;

                &.active{
                    font-weight: 500;
                }
            }

        }

        .right{
            position: relative;
            width: 80%;
            float: right;
            right: -30%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .scroll{

                overflow-y: auto;

                .imgContainer{
                
                    margin: 10px 20px;
                    
                    img{
                        width: 35%;
                        margin: 2%;
                        border: 7px solid white;
                        transition: transform 0.3s;
                        
                    }
    
                    img:hover{
                        transform: scale(1.05);
                    }
    
                }

            }
            
        }

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            background-color: #9c424d;
            .left {
                background-color: #9c424d;
                width: 100%;
                max-width: 100%;
                margin-left: 2%;
                margin-bottom: 2%;
                padding-bottom: 2%;
                flex-direction: row; 
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                p{
                    margin: 10px;
                    font-size: 3vw;
                }              
            }

            .right {
                position: unset;
                width: 100%;
                margin-top: 25%;
                overflow: hidden;

                .scroll{
                    .imgContainer{
                    
                        margin: 10px 20px;
                        text-align: center;
                        // display: flex;
                        // flex-direction: column;

                        img{
                            width: 35%;
                            margin: 2%;
                            border: 7px solid white;
                            transition: transform 0.3s;
                            
                        }
        
                        img:hover{
                            transform: scale(1.05);
                        }
        
                    }
    
                }
            }
        }
    }


}