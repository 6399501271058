.intro{
    background-color: #9c424d;
    .image{
        justify-content: center;
        display: flex;
        position: relative;

        .image2{
            
            width: 100%;
            top: 67.5%;
        }

        
    
        
    }

    

    .text{
        margin-top: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: auto;
            width: 50%;
            filter: grayscale(1) invert(1);
        }
    }


}