.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    background-color: #FAFAFA;
    border: 1px solid #000;
    border-radius: 14px;
    box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.2);
    padding: 32px;
    max-height: 90vh; 
    overflow-y: auto;
}

@media (max-width: 708px) {
    .modal-box {
        width: 80%; 
        padding: 16px; 
        max-height: 90vh; 
        overflow-y: auto; 
    }
}
